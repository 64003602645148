<template>
  <div>
    <van-sticky>
      <div style="position: relative; z-index: 1" v-if="webSetting.detail">
        <div class="top-bar">
          <div class="top-bar__inner">
            <a @click="toIndex()" class="bar-item">
              <i class="el-icon-s-home icon"></i>
              <span class="text">商城首页</span>
            </a>

            <!-- 登录展示 -->
            <template v-if="this.loginStatus">
              <div class="bar-item ml">
                <span class="text"
                  >欢迎您
                  <el-dropdown>
                    <span class="el-dropdown-link">
                      {{ username }}
                      <i class="el-icon-arrow-down" style="color: #ffffff"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item>
                        <span @click="toMyOrder()"
                          >我的订单</span
                        ></el-dropdown-item
                      >
                      <el-dropdown-item>
                        <span @click="toUserInfo()"
                          >个人中心</span
                        ></el-dropdown-item
                      >
                      <el-dropdown-item>
                        <span @click="toCustomer()"
                          >联系客服</span
                        ></el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </el-dropdown></span
                >
              </div>

              <span @click="handleLogout()">
                <a class="bar-item" style="cursor: pointer">
                  <i class="el-icon-switch-button icon"></i>
                  <span class="text">退出</span>
                </a>
              </span>
            </template>

            <!-- 未登录展示 -->
            <template v-if="!this.loginStatus">
              <div class="bar-item ml" @click="clickLogin()">
                <i class="el-icon-tickets icon"></i>
                <span class="text">登录 / 注册</span>
              </div>
            </template>
          </div>
        </div>

        <div class="glmall-new-header-wrapper">
          <div class="glmall-new-header">
            <div class="glmall-header-logo">
              <a href="/" class="router-link-active" style="cursor: pointer">
                <img :src="webSetting.detail.webLogo" />
              </a>
            </div>
            <div class="glmall-new-header-menu">
              <ul>
                <li class="glmall-curr">
                  <div>
                    <a @click="toIndex()">首页 </a>
                  </div>
                </li>

                <li
                  class="glmall-curr"
                  v-for="(item, index) in module.list"
                  :key="index"
                >
                  <a :href="item.link">
                    {{ item.name }}
                  </a>
                </li>
              </ul>
            </div>
            <div class="new-search-wrap">
              <div style="clear: both">
                <span>
                  <span class="el-popover__reference-wrapper">
                    <div class="search search-common el-popover__reference">
                      <el-input
                        v-model="goodsName"
                        placeholder="请输入关键字"
                        @keyup.enter.native="searchgoods"
                        class="search-input"
                      >
                      </el-input>
                      <span class="search-btn" @click="searchgoods()"
                        ><i class="el-icon-search"></i>
                        搜索
                      </span>
                    </div>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>

        <PLogin v-if="dialogLoginVisible" @close="clickLogin" />
      </div>
    </van-sticky>
  </div>
</template>

<script>
import { getToken } from "@/utils/auth";
import { mapGetters, mapState } from "vuex";
import PLogin from "@/pages/pc/common/login";

export default {
  props: ["module"],
  components: {
    PLogin,
  },
  data() {
    return {
      selectedIndex: 0,
      navList: [],
      loginStatus: false,
      goodsName: "",
      loginForm: {
        username: "",
        password: "",
        checkPass: "",
        inviterCode: "",
      },
      dialogLoginVisible: false,
      dialogRegisterVisible: false,
    };
  },
  computed: {
    ...mapGetters({}),
    ...mapState({
      webSetting: (state) => state.index.webSetting,
      producCategorytList: (state) => state.index.producCategorytList,
      bannerList: (state) => state.index.bannerList,
      newList: (state) => state.index.newList,
      recommendList: (state) => state.index.recommendList,
      hotList: (state) => state.index.hotList,
      indexBrandList: (state) => state.index.indexBrandList,
      // normalNewsList: (state) => state.index.normalNewsList,
      username: (state) => state.user.username,
      avatarPicUrl: (state) => state.user.avatarPicUrl,
      email: (state) => state.user.email,
      phone: (state) => state.user.phone,
      userid: (state) => state.user.userid,
      balanceTotal: (state) => state.balance.balanceTotal,
      totalWithdrawRejected: (state) => state.balance.totalWithdrawRejected,
      totalRechargeSuccess: (state) => state.balance.totalRechargeSuccess,
      balanceCanWithdraw: (state) => state.balance.balanceCanWithdraw,
      totalWithdrawing: (state) => state.balance.totalWithdrawing,
      totalWithdrawSuccess: (state) => state.balance.totalWithdrawSuccess,
      tableDatas: (state) => state.balance.list,
      brandListByCategoryId: (state) => state.index.brandListByCategoryId,
    }),
  },
  created() {
    //验证是否登录
    this.verifyIsLogin();
  },
  methods: {
    /**
     * 判断用户是否登录
     */
    verifyIsLogin() {
      const hasToken = getToken();
      if (hasToken) {
        this.loginStatus = true;
        this.$store.dispatch("user/info");
      }
    },

    // 跳转到详情页面
    searchgoods() {
      if (this.goodsName == "" || this.goodsName == undefined) {
        this.$message("请输入搜索关键词");
      } else {
        this.$router.push({
          name: "plist",
          query: { goodsName: this.goodsName },
        });
      }
    },

    /**
     * 登录
     */
    clickLogin() {
      // this.dialogLoginVisible = !this.dialogLoginVisible;
      this.$router.push("/plogin");
    },

    /**
     * 退出登录
     */
    handleLogout() {
      this.$store
        .dispatch("user/logout", this.loginForm)
        .then(() => {
          location.reload();
          this.$message({
            message: "注销成功！",
            type: "success",
          });
        })
        .catch(() => {
          this.$message({
            message: "注销失败！",
            type: "warning",
          });
        });
    },

    //跳转到我的订单
    toMyOrder() {
      const hasToken = getToken();
      if (!hasToken) {
        this.dialogLoginVisible = !this.dialogLoginVisible;
        return;
      }
      this.$router.push({ path: "/porder" });
    },

    //跳转到个人中心
    toUserInfo() {
      const hasToken = getToken();
      if (!hasToken) {
        this.dialogLoginVisible = !this.dialogLoginVisible;
        return;
      }
      this.$router.push({ path: "/user_info" });
    },

    /**
     * 跳转到客服
     */
    toCustomer() {
      this.$router.push({ path: "/customer" });
    },

    /**
     * 跳转到首页
     */
    toIndex() {
      this.$router.push({ path: "/index" });
    },

    isActive(tag) {
      console.log(this.$route.path);
      // return tag === this.$route.path;
      return tag.includes(this.$route.path);
    },

    /**
     * 跳转到系统页面
     */
    toNavUrl(v) {
      // var that = this;

      // this.selectedIndex = index;
      if (v.navType === "1") {
        // if (this.loginStatus) {
        this.$router.push({ path: v.navUrl });
        // } else {
        //   that.clickLogin();
        // }
      } else {
        window.open(v.navUrl, "_blank");
      }
    },
  },
};
</script>

<style>
.centet3 {
  width: 1300px;
  margin: 0 auto;
}

.public-nav {
  width: 100%;
  /* height: 80px; */
  background-color: #fff;
  /* box-shadow: 0px 0px 10px rgb(0 0 0 / 30%); */
  z-index: 99;
  /* margin: 10px 0px 10px 0px; */
}

.public-nav .header_1 {
  height: 100%;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  margin: 0 auto;
  overflow: hidden;
}

.public-nav .header_1 .fl {
  width: 160px;
  height: 97.7px;
  display: flex;
  align-items: center;
}

.public-nav .header_1 .fl img {
  max-width: 100%;
  max-height: 100%;
}

.public-nav .header_1 .fa {
  display: flex;
  height: 100%;
  margin-right: 30px;
  margin-top: 20px;
  cursor: pointer;
}

.public-nav .header_1 .fa a {
  display: flex;
  height: 100%;
  padding: 10px 10px 25px 10px;
  margin: 0 20px;
  font-size: 18px;
  font-weight: bold;
  align-items: center;
  position: relative;
}

.public-nav .header_1 .fa a.hover::after {
  content: "";
  position: absolute;
  bottom: 12px;
  left: 50%;
  /* 水平居中 */
  transform: translateX(-50%);
  /* 将元素向左平移自身宽度的一半 */
  width: 100%;
  height: 5px;
  border-radius: 5px;
  background: #59f;
}

.public-nav .header_1 .fr {
  width: 260px;
  height: 40px;
  /* border: 1px solid #bbc8d5; */
  /* border: 0px; */
  /* border-radius: 50px; */
}

.public-nav .header_1 .fr form {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  border: 1px solid var(--bs-gray-200);
  border-radius: 20px;
  overflow: hidden;
}

.public-nav .header_1 .fr form .search-focus {
  width: calc(100% - 60px);
  height: 40px;
  border: 0;
  font-size: 14px;
  text-indent: 20px;
}

.public-nav .header_1 .fr form .search-submit {
  width: 50px;
  height: 30px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: #59f;
  border: 0;
  border-radius: 20px;
  margin-left: 5px;
  transition: 0.2s;
}

.public-nav .header_1 .fr form .search-submit:hover {
  background: #73abff;
}

/* 客服 */
.customer-service {
  position: fixed;
  right: 20px;
  bottom: 100px;
  z-index: 9999;
}

.service-button,
.faq-button {
  background-color: #12b7f5;
  color: #fff;
  font-size: 14px;
  padding: 20px 50px;
  border: none;
  border-radius: 50px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.service-icon {
  transform: scale(1.2);
  /* 调整图标的放大倍数 */
}

/* 导航top */

.top-bar {
  /* background-color: #f4f5f6; */
  width: 100%;
  /* height: 0.7rem; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: #f5f5f5; */
  background: #333;
}

.top-bar .top-bar__inner {
  width: 1300px;
  height: 36px;
  margin: auto;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  font-size: 13px;
}

.top-bar .top-bar__inner .bar-item {
  cursor: pointer;
  display: flex;
  /* align-items: center; */
  /* color: #6c6c6c; */
  color: #b0b0b0;
  padding: 0 8px;
}

.top-bar .top-bar__inner .bar-item.mr {
  margin-right: auto;
}

.top-bar .top-bar__inner .bar-item.ml {
  margin-left: auto;
}

.top-bar .top-bar__inner .bar-item img {
  height: 14px;
  margin-right: 2px;
}

.search-container {
  display: flex;
  align-items: center;
}

.search-input {
  margin-right: -1px;
  /* 去除输入框的右边边框 */
  border-radius: 0;
  /* 去除输入框的圆角 */
}

.search-btn {
  border-radius: 0 4px 4px 0;
  /* 设置按钮的右边圆角 */
}

.icon {
  font-size: 18px;
  margin-right: 6px;
}

.text {
  font-size: 14px;
}

.glmall-new-header-wrapper {
  background: #fff;
  z-index: 61;
  width: 100%;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.glmall-new-header-wrapper.fixed {
  -webkit-backdrop-filter: blur(2px) brightness(0.2);
  backdrop-filter: blur(2px) brightness(0.2);
}

.glmall-new-header-wrapper .glmall-new-header {
  width: 1300px;
  margin: 0 auto;
  position: relative;
  height: 80px;
  /* background: #fff; */
}

.glmall-new-header-wrapper .glmall-new-header .glmall-header-logo {
  line-height: 70px;
  height: 80px;
  float: left;
  margin-right: 28px;
  font-size: 0;
}

.glmall-new-header-wrapper .glmall-new-header .glmall-header-logo img {
  max-height: 80px;
  max-width: 240px;
  vertical-align: middle;
}

.glmall-new-header-wrapper .glmall-new-header-menu {
  float: left;
  position: relative;
  /* max-width: 745px; */
  height: 80px;
  font-size: 0;
}

.glmall-new-header-wrapper .glmall-new-header-menu ul {
  height: 80px;
}

.glmall-new-header-wrapper .glmall-new-header-menu .glmall-menu-icon {
  position: absolute;
  z-index: 10;
  background-color: #ff5436;
  background-image: radial-gradient(
    top left,
    #ff9719 0,
    #ff5535 60%,
    #ff5535 100%
  );
  color: #fff;
  font-size: 12px;
  font-size: 11px\9;
  line-height: 15px;
  border-radius: 2px 2px 2px 0;
  top: 13px;
  margin-left: -6px;
  padding: 0 1px;
  max-width: 60px;
}

.glmall-new-header-wrapper .glmall-new-header-menu .glmall-menu-icon span {
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
  max-width: 60px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: top;
}

.glmall-new-header-wrapper .glmall-new-header-menu .glmall-menu-icon:after {
  content: "";
  position: absolute;
  bottom: -3px;
  left: 0;
  border-width: 2px;
  border-style: solid dashed dashed solid;
  border-color: #ff5436 transparent transparent #ff5436;
}

.glmall-new-header-wrapper .glmall-new-header-menu li {
  margin: 0;
  display: inline-block;
  vertical-align: middle;
  height: 80px;
  position: relative;
  white-space: nowrap;
  -webkit-transition: background 0.2s;
  transition: background 0.2s;
}

.glmall-new-header-wrapper .glmall-new-header-menu li a {
  padding: 0 30px;
  display: block;
  /* color: #626266; */
  font-size: 16px;
  line-height: 80px;
  position: relative;
  text-decoration: none;
  opacity: 1;
}

.glmall-new-header-wrapper .glmall-new-header-menu li.glmall-curr a,
.glmall-new-header-wrapper .glmall-new-header-menu li.glmall-curr a:hover,
.glmall-new-header-wrapper .glmall-new-header-menu li:hover a {
  /* color: #3b8cfe; */
  color: #000000;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
}

.new-search-wrap {
  float: right;
  margin-top: 21px;
}

.search-common {
  position: relative;
  width: 210px;
  height: 40px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 18px;
}

.search-common:hover {
  background: rgba(0, 0, 0, 0.8);
}

.search-common input {
  /* position: absolute; */
  top: 0;
  left: 0;
  outline: none;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 18px;
  /* width: 106px; */
  /* height: 34px; */
  line-height: 34px;
  padding: 0 16px;
  color: #fff;
}

.search-common input::input-placeholder {
  color: hsla(0, 0%, 100%, 0.4);
}

.search-common input::-ms-input-placeholder {
  color: hsla(0, 0%, 100%, 0.4);
}

.search-common input::-webkit-input-placeholder {
  color: hsla(0, 0%, 100%, 0.4);
}

.search-common input::-moz-placeholder {
  color: hsla(0, 0%, 100%, 0.4);
}

.search-common .close {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAMAAABhq6zVAAAAOVBMVEUAAAD///+qqqrMzMzR0dHLy9HNyNHNydDKytHLy9LMyc/JydDLyNHLyNHKyNDLyNDLydHMydHKyM9uTH1zAAAAE3RSTlMAAgMFCyw4R0hJUFFTVGFiY2Rm0nbSTQAAAFVJREFUCNdVjTEOgDAMxC4NoSGUAv7/Y1kQEpsHy5ZkUXNWmCQtAwDGItnLMJoC1oRcIVSwee+eULqB7t6BSzeQ7gnMT9ug/oH2pY/2n0oW+3nuYdID7RMGVn/YUhcAAAAASUVORK5CYII=)
    no-repeat 50%;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 70px;
  top: 7px;
  cursor: pointer;
  display: block;
}

.search-common .close:hover {
  -webkit-filter: saturate(135%) brightness(106%);
  filter: saturate(135%) brightness(106%);
}

.search-common .search-btn {
  width: 72px;
  height: 39px;
  line-height: 36px;
  border-radius: 18px;
  cursor: pointer;
  position: absolute;
  right: -1px;
  top: -1px;
  text-align: center;
  color: #fff;
  background: linear-gradient(135deg, #76b3ff, #3b8cfe);
  background: -webkit-linear-gradien(135deg, #76b3ff 0, #3b8cfe 100%);
}

.search-common .search-hot {
  position: absolute;
  left: 0;
  top: 31px;
  z-index: 65;
  padding-top: 12px;
}

.search-hot .search-hot-box {
  width: 186px;
  background: #fff;
  -webkit-box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.4);
  border-radius: 12px;
  overflow: hidden;
  padding: 12px;
}

.search-hot .search-hot-bd {
  font-size: 14px;
  color: #717175;
}

.search-hot .search-hot-bd span {
  font-size: 12px;
  color: #8d8e99;
  padding: 0 10px;
  height: 24px;
  line-height: 24px;
  background: #f7f8fa;
  border-radius: 12px;
  display: inline-block;
  margin-right: 8px;
  margin-top: 8px;
  cursor: pointer;
}
</style>
