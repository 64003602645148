<template>
  <div>
    <div>
      <div v-for="(item, index) in attr.list" :key="index">
        <div style="background: #f9f9f9;padding:5px 15px;margin-bottom:10px">
          <el-form label-position="left" label-width="80px">
            <el-form-item label="标题">
              <el-input v-model="item.title"></el-input>
            </el-form-item>

            <el-form-item label="链接">
              <div @click="openLinkModal(index)">
                <el-input v-model="item.link" readonly />
              </div>
            </el-form-item>

            <el-form-item label="图标">
              <div @click="openImageModal(index)">
                <div class="upload-box" v-if="!item.icon">
                  <a-icon type="plus" />
                </div>
                <img v-else :src="item.icon" style="width: 100%;" />
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>

      <div>
        <a-button type="primary" @click="addLink" block>添加</a-button>
      </div>
    </div>

    <!-- 公共链接模态框 -->
    <LinkModal ref="linkModal" @linkCallback="linkCallback" />

    <!-- 公共图片模态框 -->
    <ImageModal ref="imageModal" @imageCallback="imageCallback" />
  </div>
</template>

<script>
import LinkModal from "@/components/Link/index.vue";
import ImageModal from "@/components/IMAGE/index.vue";

export default {
  components: {
    LinkModal,
    ImageModal
  },
  props: ["attr"],
  data() {
    return {
      selectedIndex: undefined
    };
  },
  methods: {
    /**
     * 添加链接
     */
    addLink() {
      this.attr.list.push({
        title: "",
        link: "",
        icon: ""
      });
    },

    /**
     * 删除链接
     */
    deleteLink(index) {
      if (this.attr.list.length <= 1) {
        this.$message.error("至少保留一个！");
        return;
      }
      this.attr.list.splice(index, 1);
    },

    /**
     * 打开链接模态框
     */
    openLinkModal(index) {
      this.selectedIndex = index;
      this.$refs.linkModal.handleAdd();
    },

    /**
     * 更新链接
     */
    linkCallback(aaa) {
      this.attr.list[this.selectedIndex].link = aaa;
    },

    /**
     * 打开图片组件
     */
    openImageModal(index) {
      this.selectedIndex = index;
      this.$refs.imageModal.handleAdd();
    },

    /**
     * 图片选择
     */
    imageCallback(row) {
      this.attr.list[this.selectedIndex].icon = row.imgUrl;

      this.$forceUpdate();
    }
  }
};
</script>
