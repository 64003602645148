<template>
  <div class="module-common pc_style">
    <div :class="tops ? 'navbar' : 'navbar-top'">
      <el-tooltip
        v-for="(item, index) in module.list"
        :key="index"
        class="item"
        effect="dark"
        :content="item.title"
        placement="left"
      >
        <a><img :src="item.icon" style="width:60%"/></a>
      </el-tooltip>

      <el-tooltip
        class="item"
        effect="dark"
        content="返回顶部"
        placement="left"
      >
        <a @click="gotop">
          <i class="el-icon-caret-top"></i>
          <br />
        </a>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
export default {
  props: ["module"],
  data() {
    return {
      showQR: false,
      tops: false,
      heights: window.innerHeight,
      scrolltop: ""
    };
  },
  mounted() {
    window.addEventListener("scroll", this.scrollToTop);
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
  methods: {
    showQRCode() {
      //显示二维码
      this.showQR = true;
    },
    hideQRCode() {
      //隐藏二维码
      this.showQR = false;
    },
    scrollToTop() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      this.scrolltop = scrollTop;
      if (parseInt(this.scrolltop) > parseInt(this.heights) / 3) {
        this.tops = true;
      } else {
        this.tops = false;
      }
    },
    gotop() {
      let timer = setInterval(() => {
        document.documentElement.scrollTop = document.body.scrollTop =
          this.scrolltop - 90;
        if (this.scrolltop === 0) {
          clearInterval(timer);
        }
      }, 15);
    }
  }
};
</script>

<style scoped>
.navbar {
  text-align: center;
  background-color: white;
  position: fixed;
  top: 68%;
  right: 2%;
  width: 55px;
  /* height: 220px; */
  z-index: 999;
  border-radius: 10px;
}
.navbar-top {
  text-align: center;
  background-color: white;
  position: fixed;
  top: 68%;
  right: 2%;
  width: 55px;
  /* height: 165px; */
  z-index: 999;
  border-radius: 10px;
}
.navbar a {
  color: rgb(0, 0, 0);
  text-decoration: none;
  width: 100%;
  height: 55px;
  display: block;
  font-size: 30px;
  padding-top: 5px;
}
.navbar a:last-child {
  color: rgb(0, 0, 0);
  text-decoration: none;
  width: 100%;
  height: 55px;
  display: block;
  font-size: 15px;
  border-radius: 0px 0px 10px 10px;
}
.navbar a:first-child {
  color: rgb(0, 0, 0);
  text-decoration: none;
  width: 55px;
  height: 55px;
  display: block;
  font-size: 30px;
  border-radius: 10px 10px 0px 0px;
}
.navbar a:hover {
  background-color: #d9534f;
  color: white;
}
.navbar-top a {
  color: rgb(0, 0, 0);
  text-decoration: none;
  width: 100%;
  height: 55px;
  display: block;
  font-size: 30px;
  padding-top: 5px;
}
.navbar-top a:last-child {
  color: rgb(0, 0, 0);
  text-decoration: none;
  width: 100%;
  height: 55px;
  display: block;
  font-size: 30px;
  border-radius: 0px 0px 10px 10px;
}
.navbar-top a:first-child {
  color: rgb(0, 0, 0);
  text-decoration: none;
  width: 55px;
  height: 55px;
  display: block;
  font-size: 30px;
  border-radius: 10px 10px 0px 0px;
}
.navbar-top a:hover {
  background-color: #d9534f;
  color: white;
}
.qrShow {
  position: fixed;
  top: calc(68% + 80px);
  right: calc(3% + 60px);
  z-index: 999;
  background-color: rgb(179, 179, 179);
  width: 110px;
  height: 110px;
  color: white;
  text-align: center;
  border-radius: 10px;
  font-family: sans-serif;
  padding-top: 5px;
}
.qrShow:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border: 8px solid;
}
.right:after {
  border-left-color: rgb(179, 179, 179);
  left: 100%;
  top: 50%;
  margin-top: -7px;
}
</style>
